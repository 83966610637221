:root {
  --colour-primary: #2980b9;
  --colour-secondary: #3498db;
  --colour-grey: lightgray;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
}

.center {
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

label {
  padding-right: 20px;
}

.formElement {
  padding-bottom: 15px;
}

.user-data-form {
  max-width: 300px;
}

.form__input {
  display: block;
  font-size: 22px;
  border-radius: 5px;
  width: 100%;
  padding: 5px 15px;
}

.form__input:focus {
  background-color: var(--colour-grey);
} 

.form__gdpr-wrapper {
  display: flex;
  align-items: flex-start;
}

.form__checkbox {
  flex: 0 0 40px;
  height: 25px;
  width: 25px;
}

.btn {
  font-size: 24px;
  padding: 10px 20px;;
  border: none;
  background-color: var(--colour-primary);
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.btn:disabled {
  background-color: var(--colour-grey);
}

.btn:hover {
  background-color: var(--colour-secondary);
}

.form__gdpr-text {
  margin: 0;
  padding: 0;
}

ul {
  margin: 20px;
}

.answer__radio {
  display: flex;
  align-items: center;
}

.answer__radio-button {
  height: 25px;
  width: 25px;
  margin-right: 20px;
}

.progress {
  text-align: center;
}

.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.question__answer {
  margin-bottom: 30px;
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recommended-course {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.recommended-course__content {
  padding: 40px;
  text-align: center;
}

.recommended-course__image {
  width: 50%;
}

@media screen and (max-width: 600px) {
  .recommended-course {
    flex-direction: column;
  }
}

.u-center {
  text-align: center;
}

.u-padding-20 {
  padding: 20px;
}

.u-padding-10 {
  padding: 10px;
}